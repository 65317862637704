export default class StatisticsPerCity {
  valor!: number;

  total!: number;

  total2!: number;

  municipioId!: number;

  municipio!: string;

  ufId!: number;

  estado!: string;

  siglaEstado!: string;
}
