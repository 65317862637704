export enum ConversationMessageTypeEnum {
  Text = 'text',
  Image = 'image',
  Audio = 'audio',
  Document = 'document',
  Template = 'template',
  Video = 'video',
  Sticker = 'sticker',
  Voice = 'voice',
  Button = 'button',
  Contacts = 'contacts',
  Location = 'location',
  Unknown = 'unknown',
  Interactive = 'interactive',
  Ephemeral = 'ephemeral',
}
