import { inject } from 'inversify-props';
import HttpService from '@/services/http.service';
import SessionService from '@/services/session.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';

export default abstract class BaseService {
  @inject(InjectionIdEnum.HttpService)
  protected httpService!: HttpService;

  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  getApiPath(): string {
    const { apiUrl } = this.sessionService;

    if (!apiUrl) {
      throw new Error('Api url not defined');
    }

    return apiUrl;
  }

  getApi360Path(): string {
    const { apiUrl360 } = this.sessionService;

    if (!apiUrl360) {
      throw new Error('Api url 360 not defined');
    }

    return apiUrl360;
  }

  getApiToken(): string {
    const { apiToken } = this.sessionService;

    if (!apiToken) {
      throw new Error('Api token not defined');
    }

    return apiToken;
  }
}
