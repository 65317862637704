import { kebabCase } from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const dateTimeFormats: VueI18n.DateTimeFormats = {
  'en-us': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    dateTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    shortTime: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
  'pt-br': {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    dateTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    shortTime: {
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};

const numberFormats: VueI18n.NumberFormats = {
  'en-us': {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    numeral: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  'pt-br': {
    currency: {
      style: 'currency',
      currency: 'BRL',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    numeral: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
};

export const i18n = new VueI18n({
  silentTranslationWarn: true,
  dateTimeFormats,
  numberFormats,
});

const loadedLanguages: string[] = [];

export const loadLanguageAsync = (lang: string, translation: string | string[]): Promise<string> => {
  if (translation instanceof Array) {
    translation.forEach(async (t) => {
      loadLanguageAsync(lang, t);
    });
    return Promise.resolve(lang);
  }

  if (!translation || loadedLanguages.includes(`${lang}${translation}`)) {
    i18n.locale = lang;
    return Promise.resolve(lang);
  }

  const filename = kebabCase(translation);

  return import(`@/lang/${lang}/${filename}.js`).then((messages) => {
    loadedLanguages.push(`${lang}${translation}`);

    const localeMessages = {
      ...i18n.getLocaleMessage(lang),
      [translation]: messages.default,
    };
    i18n.setLocaleMessage(lang, localeMessages);

    i18n.locale = lang;

    return lang;
  });
};

/*
 * For now it is returned always pt-br.
 * In the future will be implemented a improvement to get locale from browser
 */
export const getCurrentLocale = (): string => 'pt-br';
