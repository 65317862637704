import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import GCGeneralConfigModel from '@/models/gc/configuration.model';

@injectable()
export default class GCGeneralConfigService extends BaseService {
  public async loadConfig(): Promise<GCGeneralConfigModel> {
    try {
      return (await this.httpService.get(
        GCGeneralConfigModel,
        `${this.getApiPath()}/gestao/configuration/general`,
      )) as GCGeneralConfigModel;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
