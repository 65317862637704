import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import BaseService from '@/services/base-service';
import OrderModel from '@/models/crm/order.model';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { RequestConfig } from '@/interfaces/request-config.interface';
import DynamicFieldModel from '../../models/dynamic-field.model';

@injectable()
export default class OrderService extends BaseService {
  async getOrders(clientId: string): Promise<OrderModel[]> {
    const params: RequestConfig = {
      params: {
        cnpj: clientId,
        reference: dayjs()
          .year(2000)
          .startOf('year')
          .format('YYYY-MM-DD'),
      },
    };
    return (await this.httpService.get(OrderModel, `${this.getApiPath()}/crm/pedido/list`, params)) as OrderModel[];
  }

  async getOrdersPeriod(clientId: string, startDate: Date, endDate: Date): Promise<OrderModel[]> {
    const params: RequestConfig = {
      params: {
        cnpj: clientId,
        reference: dayjs(startDate)
          .format('YYYY-MM-DD'),
        finalDate: dayjs(endDate)
          .format('YYYY-MM-DD'),
      },
    };
    return (await this.httpService.get(OrderModel, `${this.getApiPath()}/crm/pedido/list`, params)) as OrderModel[];
  }

  async getOrder(dataSourceId: number, companyId: string, orderId: string): Promise<OrderModel> {
    return (await this.httpService.get(
      OrderModel,
      `${this.getApiPath()}/crm/pedido?idDataSource=${dataSourceId}&codEmpresa=${companyId}&codPedido=${orderId}`,
    )) as OrderModel;
  }

  async sendOrderByEmail(model: EmailModel<IKeyValue<string | number>[]>): Promise<EmailModel> {
    return (await this.httpService.post(
      EmailModel,
      `${this.getApiPath()}/crm/pedido/send-by-email`,
      classToPlain(cloneDeep(model)),
    )) as EmailModel;
  }

  async getDynamicFieldValues(code: number): Promise<DynamicFieldModel[]> {
    return (await this.httpService.get(
      DynamicFieldModel,
      `${this.getApiPath()}/coringas/${code}`,
    )) as DynamicFieldModel[];
  }

  static getNfeUrl(nfeKey: string): string {
    const nfePortal = 'https://www.nfe.fazenda.gov.br/PORTAL/consultaRecaptcha.aspx';
    return `${nfePortal}?tipoConsulta=completa&tipoConteudo=7PhJ+gAVw2g=&nfe=${nfeKey}`;
  }

  static generateOrderAttachmentFilename(order: OrderModel, date: Date): string {
    return `Empresa_Pedido_${order.codPedido}_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.pdf`;
  }

  static generateOrderExportFilename(date: Date): string {
    return `Empresa_Pedidos_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  static generateOrderItemsExportFilename(order: OrderModel, date: Date): string {
    return `Empresa_Itens_Pedido_${order.codPedido}_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
