import { Type } from 'class-transformer';

export default class FinancialInformationModel {
  nomeDataSource!: string;

  cnpjCpf!: string;

  codCliente!: string;

  titulo!: string;

  banco!: number;

  nomePortador!: string;

  @Type(() => Date)
  dataEmissao!: Date;

  @Type(() => Date)
  dataVencimento!: Date;

  @Type(() => Date)
  dataBaixa?: Date;

  valorLiquido!: number;

  situacao!: string;

  qtdeDiasAtraso!: number;
}
