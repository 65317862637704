import { TransformationType, TransformFnParams } from 'class-transformer';
import { IKeyValue } from '../../interfaces/key-value.interface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const JsonTransform = (params: TransformFnParams): IKeyValue<IKeyValue<string | string[]>> | string | null => {
  if (!params.value) {
    return params.value;
  }

  if (params.type === TransformationType.CLASS_TO_PLAIN) {
    return JSON.stringify(params.value);
  }

  return JSON.parse(params.value);
};
