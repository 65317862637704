import { Type } from 'class-transformer';
import GroupModel from '@/models/crm/group.model';

export default class HistoryTypeModel {
  id!: number;

  nome!: string;

  flagPassivoVenda!: number;

  @Type(() => GroupModel)
  grupoArea!: GroupModel;

  textoPadraoEvento!: string;
}
