import { injectable } from 'inversify-props';
import GCClassificationModel from '@/models/gc/configuration/classification.model';
import BaseService from '@/services/base-service';

@injectable()
export default class GCClassificationService extends BaseService {
  public async loadClassification(): Promise<GCClassificationModel[]> {
    return (await this.httpService.get(
      GCClassificationModel,
      `${this.getApiPath()}/gestao/configuration/classification`,
    )) as GCClassificationModel[];
  }

  public async reLoadDefaultTheme(): Promise<void> {
    await this.httpService.post(
      GCClassificationModel,
      `${this.getApiPath()}/gestao/configuration/refresh`,
    );
  }

  public async saveClassification(
    listOfClassification: Array<GCClassificationModel>,
  ): Promise<void> {
    await this.httpService.post(
      GCClassificationModel,
      `${this.getApiPath()}/gestao/configuration/classification`,
      listOfClassification,
      {},
      false,
    );
  }

  public static getNextId(listOfClassification: Array<GCClassificationModel>): number {
    let result = 0;

    if (listOfClassification != null && listOfClassification.length > 0) {
      listOfClassification.forEach((classification) => {
        if (result < classification.id) {
          result = classification.id;
        }
      });
    }

    return result + 1;
  }
}
