import { Type } from 'class-transformer';
import { VisitMadeStatusEnum } from '@/enums/crm/visit-made-status.enum';

export default class VisitMadeModel {
  id!: number;

  relViagem!: string;

  @Type(() => Date)
  dataVisita!: Date;

  tipo!: number;

  descricao!: string;

  situacao!: VisitMadeStatusEnum;
}
