import { Type } from 'class-transformer';

export default class OrderFiscalDocumentModel {
  chaveNFE!: string;

  codPedido!: string;

  codTransp!: string;

  @Type(() => Date)
  dataEmissao!: Date;

  nomeRedespacho!: string;

  nomeTransp!: string;

  numeroNF!: string;

  situacao!: string;

  valorLiquido!: number;
}
