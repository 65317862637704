export default class DynamicFieldModel {
  id!: number;

  codSql!: number;

  valor!: string;

  chaveIntegracao!: string;

  flagEntraNoContrato!: number;
}
