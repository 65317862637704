import { Transform, Type } from 'class-transformer';
import UserModel from '@/models/user.model';
import OriginModel from '@/models/crm/origin.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import RepresentativeModel from '@/models/crm/representative.model';
import ProcessModel from '@/models/crm/process.model';
import ProspectModel from '@/models/crm/prospect.model';
import GroupModel from './group.model';
import { DateTransform } from '@/utils/value-transformers/date.transform';
import { TimeTransform } from '@/utils/value-transformers/time.transform';
import ActivityEventAttachmentModel from './activity-event-attachment.model';
import { ClientTypeEnum } from '../../enums/client-type.enum';

export default class ActivityEventModel {
  id!: number;

  tipo!: ClientTypeEnum;

  cnpj?: string;

  nome!: string;

  titulo!: string;

  descricao!: string;

  observacao!: string;

  @Transform(DateTransform)
  dataHoraInicio!: Date;

  @Transform(DateTransform)
  dataHoraFim!: Date;

  timed!: boolean;

  @Type(() => RepresentativeModel)
  representante?: RepresentativeModel;

  situacao!: number;

  diaInteiro!: number;

  @Type(() => UserModel)
  atendente?: UserModel;

  idRetorno!: number;

  @Transform(DateTransform)
  dataBaixa!: Date;

  @Transform(TimeTransform)
  horaBaixa!: string;

  @Type(() => UserModel)
  usuarioBaixa!: UserModel;

  @Type(() => OriginModel)
  origem!: OriginModel;

  @Type(() => HistoryTypeModel)
  tipoHistorico!: HistoryTypeModel;

  grupo?: GroupModel;

  flagHistorico!: number;

  codCalcCliente!: number;

  flagEfetuouVenda!: number;

  flagEnviarEmail!: number;

  flagAgendaPrivada!: number;

  flagVisualizarCliGrupo!: number;

  parecerBaixa!: string;

  idFila!: number;

  idVisita!: number;

  flagImportacao!: number;

  titulos!: string;

  tipoTitulo!: number;

  idInstrucaoFin!: number;

  @Transform(DateTransform)
  inclusaoData!: Date;

  @Type(() => UserModel)
  inclusaoUsuarioId!: UserModel;

  @Type(() => UserModel)
  alteracaoUsuarioId!: UserModel;

  @Type(() => UserModel)
  usuarioExclusao!: UserModel;

  @Transform(DateTransform)
  dataHoraExclusao!: Date;

  @Transform(DateTransform)
  dataUltimoEmailAtrasado!: Date;

  @Type(() => ProcessModel)
  processo!: ProcessModel;

  @Type(() => ProspectModel)
  prospect?: ProspectModel;

  @Type(() => ActivityEventAttachmentModel)
  anexos!: ActivityEventAttachmentModel[];

  styleName?: string;

  flagReagendada!: boolean;
}
