import Vue from 'vue';
import Vuex from 'vuex';
import NotificationModel from '@/models/notification.model';
import ConversationContactModel from '@/models/crm/conversation-contact.model';
import ContactModel from '@/models/crm/contact.model';
import ConversationModel from '@/models/crm/conversation.model';
import { ICreateConversation } from '@/interfaces/crm/create-conversation.interface';

Vue.use(Vuex);

class State {
  notifications: NotificationModel[] = [];

  createConversation: { waContact?: ConversationContactModel; contact?: ContactModel } = {
    waContact: undefined,
    contact: undefined,
  };

  openConversation: ConversationModel | null = null;
}

export default new Vuex.Store({
  state: new State(),
  mutations: {
    addNotification: (state, payload: NotificationModel, distinct = true) => {
      if (distinct) {
        state.notifications = state.notifications.filter((item) => item.text !== payload.text);
      }

      state.notifications = [...state.notifications, payload];
    },
    createConversation: (state, payload: ICreateConversation) => {
      state.createConversation = payload;
    },
    openConversation: (state, payload: ConversationModel) => {
      state.openConversation = payload;
    },
  },
  getters: {
    notifications: (state) => state.notifications.filter((item) => item.active),
  },
});
