import VariableColumnModel from '@/models/configurator/variables/variable-column.model';
import BaseService from '@/services/base-service';

export default class VariableColumnService extends BaseService {
  async loadColumns(): Promise<VariableColumnModel[]> {
    return (await this.httpService.get(
      VariableColumnModel,
      `${this.getApiPath()}/email/columns`,
    )) as VariableColumnModel[];
  }
}
