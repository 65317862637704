import VariableTextModel from '@/models/configurator/variables/variable-text.model';
import BaseService from '@/services/base-service';

export default class VariableTextService extends BaseService {
  async getVariables(): Promise<VariableTextModel[]> {
    return (await this.httpService.get(
      VariableTextModel,
      `${this.getApiPath()}/email/variables`,
    )) as VariableTextModel[];
  }
}
