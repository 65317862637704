export default class CallCenterUserModel {
  nome!: string;

  usuario!: string;

  ramal!: string;

  idUsuario!: string;

  ativo!: boolean;

  disponivel!: boolean;

  ultimoAcesso!: Date;

  dataUltimaParada!: Date;

  status!: string;

  admin!: boolean;
}
