import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import ServiceQueueTypeModel from '@/models/crm/service-queue-type.model';
import ClientModel from '@/models/crm/client.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';

@injectable()
export default class ServiceQueueService extends BaseService {
  async getTypes(): Promise<ServiceQueueTypeModel[]> {
    return (await this.httpService.get(
      ServiceQueueTypeModel,
      `${this.getApiPath()}/crm/fila-atendimento/types`,
    )) as ServiceQueueTypeModel[];
  }

  async addToServiceQueue(
    client: ClientModel,
    clientType: ClientTypeEnum,
    serviceQueueType: ServiceQueueTypeModel,
    description?: string,
  ): Promise<string> {
    const payload: IKeyValue<string | number | undefined> = {
      idTipoFila: serviceQueueType.id,
      descricao: description,
    };

    if (clientType === ClientTypeEnum.Prospect) {
      payload.idProspect = client.codCliente;
    } else {
      payload.cnpj = client.cnpjCpf;
    }

    return (await this.httpService.post(String, `${this.getApiPath()}/crm/fila-atendimento`, payload)) as string;
  }
}
