import { GCCollectionTypeEnum } from '@/enums/gc/configuration/collection-type.enum';

export default class GCCollectionModel {
  id: number;

  collectionNumber = 0;

  originalCollectionNumber = 0;

  collectionType!: GCCollectionTypeEnum;

  constructor(
    id: number,
    collectionNumber = 0,
    collectionType = GCCollectionTypeEnum.CantBuy,
    originalCollectionNumber = 0,
  ) {
    this.id = id;
    this.collectionNumber = collectionNumber;
    this.collectionType = collectionType;
    this.originalCollectionNumber = originalCollectionNumber;
  }
}
