import { Type } from 'class-transformer';
import RepresentativeModel from '@/models/crm/representative.model';
import UserModel from '@/models/user.model';
import { ActivityEventStatusEnum } from '@/enums/crm/activity-event-status.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ProcessModel from './process.model';
import HistoryTypeModel from './history-type.model';

export default class ActivityCalendarEventModel {
  id!: number;

  tipo!: ClientTypeEnum;

  cnpj!: string;

  idProspect?: number;

  nome!: string;

  cidade!: string;

  @Type(() => HistoryTypeModel)
  tipoHistorico?: HistoryTypeModel;

  @Type(() => RepresentativeModel)
  representante!: RepresentativeModel;

  situacao!: ActivityEventStatusEnum;

  @Type(() => UserModel)
  atendente!: UserModel;

  @Type(() => UserModel)
  usuarioInclusao!: UserModel;

  titulo!: string;

  descricao!: string;

  dataInicio!: Date;

  dataFim!: Date;

  styleName!: string;

  diaTodo!: boolean;

  efetuouVenda!: number;

  processo!: ProcessModel;
}
