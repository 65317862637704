import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';
import GroupModel from './group.model';
import HistoryTypeModel from './history-type.model';
import OriginModel from './origin.model';

export default class CallCenterFinishCallModel {
  descricao!: string | null;

  origem!: OriginModel | null;

  tipoHistorico!: HistoryTypeModel | null;

  grupoArea!: GroupModel | null;

  resultado!: CallCenterCallTypeEnum | null;
}
