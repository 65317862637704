export enum CallCenterEventEnum {
  UserLogged = 'USER_LOGGED',
  UserLogout = 'USER_LOGOUT',
  Break = 'BREAK',
  Avaiable = 'AVAIABLE',
  Error = 'ERROR',
  NewCall = 'NEW_CALL',
  MissedCall = 'MISSED_CALL',
  Queue = 'QUEUE',
  Pending = 'PENDING',
  CallInProgress = 'CALL_IN_PROGRESS',
  QualifyCall = 'QUALIFY_CALL',
  FinishCall = 'FINISH_CALL',
}
