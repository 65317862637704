import { injectable, inject } from 'inversify-props';
import VueRouter, { RawLocation, Location, Route } from 'vue-router';
import isObject, { isUndefined } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '@/services/session.service';
import router from '@/router';

@injectable()
export default class RouterService {
  private vueRouter!: VueRouter;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  constructor() {
    this.vueRouter = router;
  }

  navigate(location: RawLocation): Promise<Route | boolean> {
    let transformedLocation = location;

    if (isObject(location)) {
      transformedLocation = location as Location;
      transformedLocation.query = {
        ...transformedLocation.query,
        token: this.sessionService.apiToken,
        apiUrl: this.sessionService.apiUrl,
        apiUrl360: this.sessionService.apiUrl360,
      };

      if (!isUndefined(this.sessionService.socketIOUrl)) {
        transformedLocation.query = {
          ...transformedLocation.query,
          socketIOUrl: this.sessionService.socketIOUrl,
        };
      }

      if (!isUndefined(this.sessionService.builtInMode)) {
        transformedLocation.query = {
          ...transformedLocation.query,
          builtInMode: this.sessionService.builtInMode ? 'true' : 'false',
        };
      }

      if (!isUndefined(this.sessionService.hideAppBar)) {
        transformedLocation.query = {
          ...transformedLocation.query,
          hideAppBar: this.sessionService.hideAppBar ? 'true' : 'false',
        };
      }

      if (!isUndefined(this.sessionService.clientType)) {
        transformedLocation.query = {
          ...transformedLocation.query,
          clientType: this.sessionService.clientType,
        };
      }
    }

    return this.router()
      .push(transformedLocation)
      .catch((error) => {
        if (VueRouter.isNavigationFailure(error, VueRouter.NavigationFailureType.duplicated)) {
          return false;
        }

        throw error;
      });
  }

  router(): VueRouter {
    return this.vueRouter;
  }

  route(): Route {
    const { currentRoute } = this.router();
    return currentRoute;
  }
}
