import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import ClonedUploadModel from '@/models/crm/cloned-upload.model';

@injectable()
export default class MetasUploadService extends BaseService {
  delete(filePath: string): Promise<void> {
    return this.httpService.delete(`${this.getApiPath()}/cr/upload?filePath=${filePath}`);
  }

  async clone(
    targetFilePath: string,
    filename: string,
    usuarioId: number,
    place: string,
    hash: string,
  ): Promise<ClonedUploadModel> {
    return (await this.httpService.post(ClonedUploadModel, `${this.getApiPath()}/cr/upload/clone`, {
      targetFilePath,
      filename,
      usuarioId,
      place,
      hash,
    })) as ClonedUploadModel;
  }

  getUploadUrl(): string {
    return `${this.getApiPath()}/cr/upload`;
  }

  getDownloadUrl(path: string): string {
    return `${this.getApiPath()}/cr/upload/download${path}`;
  }
}
