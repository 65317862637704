export default class RepresentativeModel {
  codigo!: string;

  nome!: string;

  situacao!: number;

  municipios!: string[];

  chaveIntegracao?: string;
}
