import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

const licenseKey = `CompanyName=IBTech,LicensedApplication=GeoVendas,LicenseType=SingleApplication,
LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-021522,
ExpiryDate=25_October_2022_[v2]_MTY2NjY1MjQwMDAwMA==0a56924f2a504fb033b37b83f1ae88c7`;
LicenseManager.setLicenseKey(licenseKey);

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  RowGroupingModule,
  ExcelExportModule,
  ServerSideRowModelModule,
]);
