import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import LeadModel from '@/models/crm/lead.model';

@injectable()
export default class LeadService extends BaseService {
  async getLeads(): Promise<LeadModel[]> {
    return (await this.httpService.get(LeadModel, `${this.getApiPath()}/crm/lead/list`)) as LeadModel[];
  }
}
