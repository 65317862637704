import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { inject, injectable } from 'inversify-props';
import { ClassConstructor, plainToClass } from 'class-transformer';
import SessionService from '@/services/session.service';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { IKeyValue } from '../interfaces/key-value.interface';

@injectable()
export default class HttpService {
  private axios: AxiosInstance;

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  constructor() {
    this.axios = axios.create();

    this.configureTokenInterceptor(this.sessionService.apiToken);
  }

  async head<T>(cls: ClassConstructor<T>, url: string, config?: RequestConfig, formatError = true): Promise<T> {
    const response = await HttpService.runRequest<T>(this.axios.head(url, config), formatError);
    return plainToClass(cls, response);
  }

  async options<T>(cls: ClassConstructor<T>, url: string, config?: RequestConfig, formatError = true): Promise<T> {
    const response = await HttpService.runRequest<T>(this.axios.options(url, config), formatError);
    return plainToClass(cls, response);
  }

  async get<T>(cls: ClassConstructor<T>, url: string, config?: RequestConfig, formatError = true): Promise<T | T[]> {
    const response = await HttpService.runRequest<T>(this.axios.get(url, config), formatError);
    return plainToClass(cls, response);
  }

  async post<T>(
    cls: ClassConstructor<T>,
    url: string,
    data?: unknown,
    config?: RequestConfig,
    formatError = true,
  ): Promise<T | T[]> {
    const response = await HttpService.runRequest<T>(this.axios.post(url, data, config), formatError);
    return plainToClass(cls, response);
  }

  async put<T>(
    cls: ClassConstructor<T>,
    url: string,
    data?: unknown,
    config?: RequestConfig,
    formatError = true,
  ): Promise<T | T[]> {
    const response = await HttpService.runRequest<T>(this.axios.put(url, data, config), formatError);
    return plainToClass(cls, response);
  }

  async patch<T>(
    cls: ClassConstructor<T>,
    url: string,
    data?: unknown,
    config?: RequestConfig,
    formatError = true,
  ): Promise<T | T[]> {
    const response = await HttpService.runRequest<T>(this.axios.patch(url, data, config), formatError);
    return plainToClass(cls, response);
  }

  delete(url: string, config?: RequestConfig, formatError = true): Promise<void> {
    return HttpService.runRequest<void>(this.axios.delete(url, config), formatError);
  }

  getAuthorizationHeader(): IKeyValue {
    return this.axios.defaults.headers.Authorization;
  }

  private configureTokenInterceptor(token: string): AxiosInstance {
    this.axios.interceptors.request.use((config) => {
      const cfg = { ...config };
      if (!cfg.headers.Authorization) {
        cfg.headers = {
          ...cfg.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return cfg;
    });

    return this.axios;
  }

  static async runRequest<T>(request: Promise<AxiosResponse<T>>, formatError = true): Promise<T> {
    try {
      const response = await request;
      return response.data;
    } catch (error) {
      if (formatError) {
        throw new Error(HttpService.formatErrors(error as AxiosError));
      }
      throw error;
    }
  }

  static formatErrors(error: AxiosError): string {
    const errorResponse = error && error.response;
    const errorStatus = errorResponse?.status;
    const errorData = errorResponse?.data;

    if (errorStatus === 404) {
      return 'Não encontrado';
    }

    if (errorStatus === 405) {
      return 'Método não permitido';
    }

    if (errorStatus === 502) {
      return `Requisição inválida: ${error?.message || error}`;
    }

    if (errorData) {
      return errorData;
    }

    return `${error?.message || error}`;
  }
}
