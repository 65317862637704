import { injectable } from 'inversify-props';
import dayjs from '@/plugins/dayjs';
import BaseService from '@/services/base-service';
import AttendanceModel from '@/models/crm/attendance.model';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { ClientTypeEnum } from '../../enums/client-type.enum';
import ResponsibleAttendantModel from '@/models/crm/responsible-attendant.model';
import CRMPanelModel from '@/models/crm/panel.model';
import CRMPanelCreationModel from '@/models/crm/panel-creation.model';
import OrderModel from '@/models/crm/order.model';

@injectable()
export default class AttendanceService extends BaseService {
  async generate(
    clientId: string,
    prospectId: number | null,
    type: ClientTypeEnum,
    index: string,
    initialDate: string,
    finalDate: string,
  ): Promise<OrderModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        prospectId,
        index,
        type,
        initialDate,
        finalDate,
      },
    };
    return (await this.httpService.get(
      OrderModel,
      `${this.getApiPath()}/crm/generate-attendance`,
      config,
    )) as OrderModel[];
  }

  async get(
    clientId: string,
    type: ClientTypeEnum,
    index: string,
    limit: number,
    offset = 0,
    initialDate: string,
    finalDate: string,
  ): Promise<AttendanceModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        index,
        limit,
        offset,
        type,
        initialDate,
        finalDate,
      },
    };
    return (await this.httpService.get(
      AttendanceModel,
      `${this.getApiPath()}/crm/attendance`,
      config,
    )) as AttendanceModel[];
  }

  static generateAttendanceExportFilename(date: Date): string {
    return `Empresa_Atendimentos_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  async getAttendantList(): Promise<ResponsibleAttendantModel[]> {
    return (await this.httpService.get(
      ResponsibleAttendantModel,
      `${this.getApiPath()}/attendant/list`,
    )) as ResponsibleAttendantModel[];
  }

  async setResponsibleAttendant(panelCreationData: CRMPanelCreationModel): Promise<CRMPanelModel> {
    return (await this.httpService.post(
      CRMPanelModel,
      `${this.getApiPath()}/attendant/upsert`,
      panelCreationData,
    )) as CRMPanelModel;
  }
}
