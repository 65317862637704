export default class SintegraProspectModel {
  inscricaoEstadual!: string;

  suframa!: string;

  razaoSocial!: string;

  codRepres!: string;

  logradouro!: string;

  numero!: string;

  complemento!: string;

  bairro!: string;

  codIBGE!: string;

  uf!: string;

  cep!: string;

  telefone!: string;

  email!: string;

  facebook!: string;

  instagram!: string;

  situacao!: string;

  observacoes!: string;

  nomeFantasia!: string;

  erro!: string;
}
