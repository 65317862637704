export enum CallCenterActionEnum {
  Login = 'login',
  Break = 'parada',
  Queue = 'fila',
  Call = 'chamada',
  Avaiable = 'livre',
  Logout = 'logout',
  Decline = 'desligar',
  Pending = 'pendencias',
  Return = 'retornar',
  AwaitingQualification = 'aguardando_qualificacao',
  SendQualification = 'qualificar',
  Transfer = 'transferir',
  CallTheNumber = 'discar',
}
