export default class OrderItemModel {
  codItem!: string;

  codPedido!: string;

  codProduto!: string;

  nomeProduto!: string;

  codColecao!: string;

  armazem!: string;

  operacao!: string;

  percRentabilidade!: string;

  qtdeCancelado!: number;

  qtdeFaturado!: number;

  qtdePedida!: number;

  qtdeSaldo!: number;

  seqSortimento!: string;

  seqTamanho!: string;

  valorRentabilidade!: number;

  valorTotal!: number;

  valorUnitarioBruto!: number;

  valorUnitarioLiquido!: number;

  get totalSaldo(): number {
    const itemsBalance = this.qtdePedida - this.qtdeFaturado - this.qtdeCancelado;
    return itemsBalance * this.valorUnitarioLiquido;
  }

  get totalFaturado(): number {
    return this.qtdeFaturado * this.valorUnitarioLiquido;
  }
}
