import Vue from 'vue';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import { cloneDeep } from 'lodash';
import vuetify from '@/plugins/vuetify';

// Fix conflict with non implement pt language in tipTap vuetify
// After added support for pt, this clone workaround must be removed
const clonedVuetify = cloneDeep(vuetify);
clonedVuetify.framework.lang.current = 'ptbr';

Vue.use(TiptapVuetifyPlugin, {
  vuetify: clonedVuetify,
  iconsGroup: 'mdi',
});
