import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import BaseService from '@/services/base-service';
import ClientManagementModel from '@/models/crm/client-management.model';

@injectable()
export default class ClientManagementService extends BaseService {
  async getClientsManagement(clientId: string): Promise<ClientManagementModel[]> {
    return (await this.httpService.get(
      ClientManagementModel,
      `${this.getApiPath()}/gestao/management/justification?cnpj=${clientId}`,
    )) as ClientManagementModel[];
  }

  static generateClientsManagementExportFilename(date: Date): string {
    return `Vendas_Gestao_Clientes_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
