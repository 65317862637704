import { Transform } from 'class-transformer';
import { DateTransform } from '@/utils/value-transformers/date.transform';
import ReasonManagementModel from '@/models/crm/reason-management.model';

export default class JustificationManagementModel {
  codSituacao?: number;

  situacao?: string;

  motivo?: ReasonManagementModel | null;

  @Transform(DateTransform)
  dataRetorno?: Date | null;

  @Transform(DateTransform)
  obsRepresData?: Date;

  obsRepres?: string;

  @Transform(DateTransform)
  obsGerenteData?: Date;

  obsGerente?: string;

  observacao?: string | null;

  codCliente?: string;

  codRepres?: string;

  colecao?: number;
}
