import { injectable } from 'inversify-props';
import GCCollectionModel from '@/models/gc/configuration/collection.model';
import BaseService from '@/services/base-service';
import { GCCollectionTypeEnum } from '@/enums/gc/configuration/collection-type.enum';

@injectable()
export default class GCCollectionService extends BaseService {
  public async loadCollections(): Promise<Array<GCCollectionModel>> {
    try {
      return (await this.httpService.get(
        GCCollectionModel,
        `${this.getApiPath()}/gestao/configuration/collection`,
      )) as GCCollectionModel[];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public static getNextId(listOfCollection: Array<GCCollectionModel>): number {
    let result = 0;

    if (listOfCollection != null && listOfCollection.length > 0) {
      listOfCollection.forEach((collection) => {
        if (result < collection.id) {
          result = collection.id;
        }
      });
    }

    return result + 1;
  }

  public static getNextCollectionNumber(listOfCollectionBase: Array<GCCollectionModel>): number {
    let result = 0;

    if (listOfCollectionBase != null && listOfCollectionBase.length > 0) {
      listOfCollectionBase.forEach((collection) => {
        if (result < collection.collectionNumber) {
          result = collection.collectionNumber;
        }
      });
    }

    return result + 1;
  }

  public static generateNewListOfCollection(
    listOfCollectionBase: Array<GCCollectionModel>,
  ): Array<GCCollectionModel> {
    const result: Array<GCCollectionModel> = [];
    listOfCollectionBase.forEach((collection) => {
      result.push(
        new GCCollectionModel(
          collection.id,
          collection.collectionNumber,
          GCCollectionTypeEnum.CantBuy,
        ),
      );
    });

    return result;
  }
}
