export default class UserSettingsModel {
  id!: number;

  nome!: string;

  flagUtilizaWhatsApp!: boolean;

  flagUtilizaAgenda!: boolean;

  flagPermiteExcluirAnexos!: boolean;

  flagPermiteCadastroAtendenteChave!: boolean;
}
