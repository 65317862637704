export default class CRMPanelModel {
  id!: number;

  cnpjCpf!: string;

  particularidades?: string;

  prospectId?: number;

  atendenteChaveId?: number;

  clienteId?: number;
}
