import B2OrderAuditModel from './order-audit.model';

export default class B2OrderAuditResponse {
  hasError!: boolean;

  customMessageError?: string;

  result!: B2OrderAuditModel;

  constructor() {
    this.hasError = false;
    this.customMessageError = '';
    this.result = new B2OrderAuditModel();
  }
}
