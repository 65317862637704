import { Type } from 'class-transformer';
import { GCClassificationUniqueTypeEnum } from '@/enums/gc/configuration/classification-unique-type.enum';
import { GCJustificationTypeEnum } from '@/enums/gc/configuration/justification-type.enum';
import GCCollectionModel from '@/models/gc/configuration/collection.model';

export default class GCClassificationModel {
  id: number;

  private name = '';

  justificationType: GCJustificationTypeEnum = GCJustificationTypeEnum.DontUse;

  @Type(() => GCCollectionModel)
  listOfCollection: Array<GCCollectionModel> = [];

  isUnique = false;

  uniqueType = GCClassificationUniqueTypeEnum.NotUnique;

  uuid!: string;

  constructor(
    id: number,
    name = '',
    justificationType: GCJustificationTypeEnum,
    listOfCollection: Array<GCCollectionModel> = [],
    isUnique = false,
    uniqueType = GCClassificationUniqueTypeEnum.NotUnique,
  ) {
    this.id = id;
    this.description = name;
    this.justificationType = justificationType;
    this.listOfCollection = listOfCollection;
    this.isUnique = isUnique;
    this.uniqueType = uniqueType;
  }

  set description(name: string) {
    this.name = name.toUpperCase();
  }

  get description(): string {
    return this.name;
  }
}
