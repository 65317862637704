import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import { RequestConfig } from '@/interfaces/request-config.interface';

@injectable()
export default class MetasPeriodoService extends BaseService {
  async getMetasPeriodos(): Promise<Array<Array<string>>> {
    const config: RequestConfig = {
    };
    return (await this.httpService.get(Array,
      `${this.getApiPath()}/cr/get-metas-periodos`, config)) as Array<Array<string>>;
  }
}
