import { Type } from 'class-transformer';

export default class ConversationMessageMediaModel {
  id!: number;

  mediaId!: string;

  path!: string;

  destination!: string;

  mediaType!: string;

  contentType!: string;

  extension!: string;

  checksum!: string;

  fileSize!: number;

  removedFromWaba!: boolean;

  @Type(() => Date)
  updateDate!: Date;

  @Type(() => Date)
  insertDate!: Date;
}
