import { injectable } from 'inversify-props';
import { classToPlain } from 'class-transformer';
import BaseService from '@/services/base-service';
import AttachmentModel from '@/models/crm/attachment.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { RequestConfig } from '@/interfaces/request-config.interface';

@injectable()
export default class AttachmentService extends BaseService {
  async getFrontageAttachments(clientId: string, type: ClientTypeEnum): Promise<AttachmentModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        type,
      },
    };

    return (await this.httpService.get(
      AttachmentModel,
      `${this.getApiPath()}/crm/anexo/foto-da-fachada`,
      config,
    )) as AttachmentModel[];
  }

  async getOtherAttachments(clientId: string, type: ClientTypeEnum): Promise<AttachmentModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        type,
      },
    };
    return (await this.httpService.get(
      AttachmentModel,
      `${this.getApiPath()}/crm/anexo/outro-anexo`,
      config,
    )) as AttachmentModel[];
  }

  async getVisitationAttachments(clientId: string, type: ClientTypeEnum): Promise<AttachmentModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        type,
      },
    };
    return (await this.httpService.get(
      AttachmentModel,
      `${this.getApiPath()}/crm/anexo/visita`,
      config,
    )) as AttachmentModel[];
  }

  async getOrderAttachments(clientId: string, type: ClientTypeEnum): Promise<AttachmentModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        type,
      },
    };
    return (await this.httpService.get(
      AttachmentModel,
      `${this.getApiPath()}/crm/anexo/pedido`,
      config,
    )) as AttachmentModel[];
  }

  async saveFrontage(attachments: AttachmentModel[], type: ClientTypeEnum): Promise<AttachmentModel[]> {
    const config: RequestConfig = {
      params: {
        type,
      },
    };

    return (await this.httpService.post(
      AttachmentModel,
      `${this.getApiPath()}/crm/anexo/foto-da-fachada`,
      classToPlain(attachments),
      config,
    )) as AttachmentModel[];
  }

  async saveOther(attachments: AttachmentModel[], type: ClientTypeEnum): Promise<AttachmentModel[]> {
    const config: RequestConfig = {
      params: {
        type,
      },
    };

    return (await this.httpService.post(
      AttachmentModel,
      `${this.getApiPath()}/crm/anexo/outro-anexo`,
      classToPlain(attachments),
      config,
    )) as AttachmentModel[];
  }

  deleteFrontage(id: number, type: ClientTypeEnum): Promise<void> {
    const config: RequestConfig = {
      params: {
        type,
      },
    };
    return this.httpService.delete(`${this.getApiPath()}/crm/anexo/foto-da-fachada/${id}`, config);
  }

  deleteOther(id: number, type: ClientTypeEnum): Promise<void> {
    const config: RequestConfig = {
      params: {
        type,
      },
    };
    return this.httpService.delete(`${this.getApiPath()}/crm/anexo/outro-anexo/${id}`, config);
  }
}
