import { injectable } from 'inversify-props';
import BaseService from '../base-service';
import CallCenterUserModel from '@/models/crm/call-center-user.model';
import CallCenterUserBreakModel from '@/models/crm/call-center-user-break.model';

@injectable()
export default class CallCenterUserService extends BaseService {
  async userLogin(userId: string): Promise<CallCenterUserModel> {
    return (await this.httpService.put(
      CallCenterUserModel,
      `${this.getApiPath()}/crm/call-center/users/login/${userId}`,
      {},
    )) as CallCenterUserModel;
  }

  async userLogout(userId: string): Promise<void> {
    return (await this.httpService.put(
      Object,
      `${this.getApiPath()}/crm/call-center/users/logout/${userId}`,
      {},
    )) as any;
  }

  async userAvaiable(userId: string): Promise<void> {
    return (await this.httpService.put(
      Object,
      `${this.getApiPath()}/crm/call-center/users/available/${userId}`,
      {},
    )) as any;
  }

  async userBreak(userId: string, breakReason: CallCenterUserBreakModel): Promise<void> {
    return (await this.httpService.put(
      Object,
      `${this.getApiPath()}/crm/call-center/users/break/${userId}`,
      breakReason,
    )) as any;
  }

  async getAll(): Promise<CallCenterUserModel[]> {
    return (await this.httpService.get(
      CallCenterUserModel,
      `${this.getApiPath()}/crm/call-center/users/`,
    )) as CallCenterUserModel[];
  }
}
