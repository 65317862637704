import { injectable } from 'inversify-props';
import LayoutEmailModel from '@/models/configurator/layouts/layout-email.model';
import BaseService from '@/services/base-service';

@injectable()
export default class LayoutEmailService extends BaseService {
  async getLayouts(): Promise<LayoutEmailModel[]> {
    return (await this.httpService.get(
      LayoutEmailModel,
      `${this.getApiPath()}/email/templates`,
    )) as LayoutEmailModel[];
  }

  async getDefaultLayout(): Promise<LayoutEmailModel> {
    return (await this.httpService.get(
      LayoutEmailModel,
      `${this.getApiPath()}/email/template/default`,
    )) as LayoutEmailModel;
  }

  async save(layout: LayoutEmailModel): Promise<LayoutEmailModel> {
    return (await this.httpService.post(
      LayoutEmailModel,
      `${this.getApiPath()}/email/template/save`,
      layout,
    )) as LayoutEmailModel;
  }

  remove(layoutCode: number): Promise<void> {
    return this.httpService.delete(`${this.getApiPath()}/email/template/${layoutCode}`);
  }
}
