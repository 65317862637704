import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import SettingsModel from '@/models/crm/settings.model';

@injectable()
export default class SettingsService extends BaseService {
  async getSettings(): Promise<SettingsModel> {
    return (await this.httpService.get(SettingsModel, `${this.getApiPath()}/crm/settings`)) as SettingsModel;
  }
}
