import dayjs from '@/plugins/dayjs';

export const toDate = (value: string): Date | null => {
  if (!value) {
    return null;
  }

  let valueToConvert = value;

  if (typeof value === 'string' && value.indexOf(':') !== -1) {
    valueToConvert = `${value.substring(0, value.toString().lastIndexOf(':') + 3)}-0300`;
  }

  return dayjs(valueToConvert).toDate();
};
