import { VueConstructor } from 'vue';
import { ICallCenter } from '@/interfaces/call-center/call-center.interface';
import CallCenter from '@/services/call-center/call-center.service';

// -----------------------------------------------------------------------------------------------
// Você pode encontrar mais informações a respeito de como realizar a implementação no link abaixo
// https://stackoverflowteams.com/c/geovendas/questions/783/784
// -----------------------------------------------------------------------------------------------

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Vue {
    readonly $callCenter: ICallCenter;
  }
}

const plugin = {
  install: (vue: VueConstructor, patnerActions: CallCenter): void => {
    const vueInstance = vue;
    const patnerInstance = patnerActions;

    vueInstance.prototype.$callCenter = {
      subscribe: patnerInstance.subscribe,
      configure: patnerInstance.configure,
      login: patnerInstance.login,
      makeAPause: patnerInstance.makeAPause,
      logout: patnerInstance.logout,
      avaiable: patnerInstance.avaiable,
      finishCall: patnerInstance.finishCall,
      decline: patnerInstance.decline,
      return: patnerInstance.return,
      transfer: patnerInstance.transfer,
      qualify: patnerInstance.qualify,
      getUserLogged: patnerInstance.getUserLogged,
      makePhoneCall: patnerInstance.makePhoneCall,
      updateCallInformation: patnerInstance.updateCallInformation,
    };
  },
};

export default plugin;
