import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import RepresentativeModel from '@/models/crm/representative.model';
import { RequestConfig } from '@/interfaces/request-config.interface';

@injectable()
export default class RepresentativeService extends BaseService {
  async getRepresentatives(clientId?: string, includeInactive = false): Promise<RepresentativeModel[]> {
    const config: RequestConfig = {
      params: {},
    };

    if (!clientId) {
      config.params.codEmpresa = clientId;
    }

    config.params.includeInactive = includeInactive;

    return (await this.httpService.get(
      RepresentativeModel,
      `${this.getApiPath()}/representante/list`,
      config,
    )) as RepresentativeModel[];
  }
}
