







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Notification from '@/components/notification.vue';

@Component({
  components: {
    Notification,
  },
})
export default class App extends Vue {}
