import { Type } from 'class-transformer';
import { ConversationTypeEnum } from '@/enums/crm/conversation-type.enum';
import { CallCenterCallTypeEnum } from '@/enums/crm/call-center-call-type.enum';
import { CallCenterCallStatusEnum } from '@/enums/crm/call-center-call-status.enum';
import ContactModel from './contact.model';
import ProspectModel from './prospect.model';
import ClientModel from './client.model';
import UserModel from '../user.model';
import HistoryTypeModel from './history-type.model';
import GroupModel from './group.model';

export default class CallCenterCallModel {
  id!: number;

  tipo!: ConversationTypeEnum;

  cnpj!: string;

  @Type(() => Date)
  dataInclusao!: Date;

  @Type(() => Date)
  dataTransferencia!: Date;

  @Type(() => Date)
  dataFila!: Date;

  @Type(() => Date)
  dataFinalizacao!: Date;

  @Type(() => Date)
  dataAtendimento!: Date;

  tipoLigacao!: CallCenterCallTypeEnum;

  statusLigacao!: CallCenterCallStatusEnum;

  protocolo!: string;

  numeroContato!: string;

  nomeContato!: string;

  idChamada!: string;

  uuid!: string;

  descricao!: string;

  statusConclusao!: CallCenterCallTypeEnum;

  tipoHistorico!: HistoryTypeModel;

  grupoArea!: GroupModel;

  @Type(() => UserModel)
  atendente!: UserModel;

  @Type(() => ContactModel)
  contato!: ContactModel;

  @Type(() => ClientModel)
  cliente!: ClientModel;

  @Type(() => ProspectModel)
  prospect!: ProspectModel;
}
