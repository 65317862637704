import { Type } from 'class-transformer';

export default class ConversationLogModel {
  conversation!: number;

  relatedConversation!: number;

  attendant!: number;

  activo!: string;

  message!: string;

  protocol!: string;

  @Type(() => Date)
  occuredAt!: Date;

  get date(): Date {
    return this.occuredAt;
  }

  get isLog(): boolean {
    return !!this.conversation;
  }
}
