import { DateHelper } from '@/utils/helpers/date-helper';
import dayjs from '@/plugins/dayjs';

export const toIsoDateTimeString = (date: Date | dayjs.Dayjs, timezone = false): string | null => {
  if (!date) {
    return null;
  }

  const value = DateHelper.formatToIsoDateTimeString(date, timezone);
  return value;
};
