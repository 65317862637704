import { injectable } from 'inversify-props';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import dayjs from '@/plugins/dayjs';
import BaseService from '@/services/base-service';
import ActivityCalendarEventModel from '@/models/crm/activity-calendar-event.model';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { PaginationModel } from '@/models/pagination.model';
import OriginModel from '@/models/crm/origin.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import GroupModel from '@/models/crm/group.model';
import UserModel from '@/models/user.model';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import EmailModel from '@/models/email.model';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { ICalendarFilter } from '../../interfaces/crm/calendar-filter.interface';
import { ActivityEventEmailOptionsEnum } from '@/enums/crm/activity-event-title-format.enum';

@injectable()
export default class ActivityService extends BaseService {
  async create(model: ActivityEventModel): Promise<number> {
    return (await this.httpService.post(
      Number,
      `${this.getApiPath()}/crm/agenda`,
      classToPlain(cloneDeep(model)),
    )) as number;
  }

  async update(model: ActivityEventModel): Promise<number> {
    return (await this.httpService.put(
      Number,
      `${this.getApiPath()}/crm/agenda/${model.id}`,
      classToPlain(cloneDeep(model)),
    )) as number;
  }

  delete(id: number): Promise<void> {
    return this.httpService.delete(`${this.getApiPath()}/crm/agenda/${id}`);
  }

  async close(id: number, consolidateSale = false, isReschedule = false): Promise<void> {
    const body = {
      efetuouVenda: consolidateSale,
      efetuouReagendamento: isReschedule,
    };

    await this.httpService.post(String, `${this.getApiPath()}/crm/agenda/${id}/close`, body);
  }

  async getEvent(id: number): Promise<ActivityEventModel> {
    return (await this.httpService.get(
      ActivityEventModel,
      `${this.getApiPath()}/crm/agenda/${id}`,
    )) as ActivityEventModel;
  }

  async sendEventByEmail(id: number, model: EmailModel<IKeyValue<string | number>[]>): Promise<EmailModel> {
    return (await this.httpService.post(
      EmailModel,
      `${this.getApiPath()}/crm/agenda/${id}/send-by-email`,
      classToPlain(cloneDeep(model)),
    )) as EmailModel;
  }

  async getCalendarEventsByClientType(
    clientId: string,
    startDate: string,
    endDate: string,
    type: ClientTypeEnum,
  ): Promise<ActivityCalendarEventModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        startDate,
        endDate,
        type,
      },
    };

    return (await this.httpService.get(
      ActivityCalendarEventModel,
      `${this.getApiPath()}/crm/agenda/calendar`,
      config,
    )) as ActivityCalendarEventModel[];
  }

  async getCalendarEventsByAttendant(
    closedOnly: boolean,
    openOnly: boolean,
    initialDate?: number,
    finalDate?: number,
    offset?: number,
  ): Promise<PaginationModel<ActivityEventModel>> {
    const config: RequestConfig = {
      params: {
        initialDate,
        finalDate,
        closedOnly,
        openOnly,
        offset,
      },
    };

    return (await this.httpService.get(
      PaginationModel,
      `${this.getApiPath()}/attendant/events`,
      classToPlain(cloneDeep(config)),
    )) as PaginationModel<ActivityEventModel>;
  }

  async getCalendarEvents(filter: ICalendarFilter): Promise<ActivityCalendarEventModel[]> {
    return (await this.httpService.post(
      ActivityCalendarEventModel,
      `${this.getApiPath()}/crm/agenda/calendar/query`,
      filter,
    )) as ActivityCalendarEventModel[];
  }

  async getEventsByProcess(processId: number): Promise<ActivityEventModel[]> {
    return (await this.httpService.get(
      ActivityEventModel,
      `${this.getApiPath()}/crm/agenda/list?processId=${processId}`,
    )) as ActivityEventModel[];
  }

  async getSummaryEvents(clientId: string, type: ClientTypeEnum, limit = 0): Promise<ActivityEventModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        limit,
        type,
      },
    };

    return (await this.httpService.get(
      ActivityEventModel,
      `${this.getApiPath()}/crm/agenda/summary-events`,
      config,
    )) as ActivityEventModel[];
  }

  async deleteAttachment(id: number, attachmentId: number): Promise<void> {
    await this.httpService.delete(`${this.getApiPath()}/crm/agenda/${id}/${attachmentId}`);
  }

  async getFormatoTitulo(): Promise<ActivityEventEmailOptionsEnum> {
    return (await this.httpService.get(Number,
      `${this.getApiPath()}/crm/agenda/formato-titulo`)) as ActivityEventEmailOptionsEnum;
  }

  async getOrigins(channel?: number): Promise<OriginModel[]> {
    const config: RequestConfig = {
      params: {},
    };

    if (channel) {
      config.params.canal = channel;
    }

    return (await this.httpService.get(OriginModel, `${this.getApiPath()}/crm/origem/list`, config)) as OriginModel[];
  }

  async getGroups(): Promise<GroupModel[]> {
    return (await this.httpService.get(GroupModel, `${this.getApiPath()}/crm/grupo/list`)) as GroupModel[];
  }

  async getHistoryTypes(groupId?: number): Promise<HistoryTypeModel[]> {
    const config: RequestConfig = {
      params: {},
    };

    if (groupId) {
      config.params.grupoAreaId = groupId;
    }

    return (await this.httpService.get(
      HistoryTypeModel,
      `${this.getApiPath()}/crm/tipo-historico/list`,
      config,
    )) as HistoryTypeModel[];
  }

  async getAttendants(newRegister = false, activityForm = false): Promise<UserModel[]> {
    const config: RequestConfig = {
      params: { isNewRegister: newRegister, isActivityForm: activityForm },
    };
    return (await this.httpService.get(UserModel, `${this.getApiPath()}/crm/usuario/list`, config)) as UserModel[];
  }

  static generateEventAttachmentFilename(event: ICalendarEvent, date: Date): string {
    return `Agenda_${event.name}_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.pdf`;
  }

  static generateGlobalEventsExportFilename(date: Date): string {
    return `Agenda_Eventos_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
