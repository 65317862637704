import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import ContactChannelModel from '@/models/crm/contact-channel.model';

@injectable()
export default class ContactChannelService extends BaseService {
  async getContactChannels(): Promise<ContactChannelModel[]> {
    return (await this.httpService.get(
      ContactChannelModel,
      `${this.getApiPath()}/crm/contact-channel/list`,
    )) as ContactChannelModel[];
  }
}
