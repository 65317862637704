import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import B2OrderAuditResponse from '@/models/b2b/audit-response.model';

@injectable()
export default class B2OrderAuditService extends BaseService {
  async getAuditInformation(): Promise<B2OrderAuditResponse> {
    if (this.sessionService.orderCode === undefined) {
      return new B2OrderAuditResponse();
    }
    return (await this.httpService.get(
      B2OrderAuditResponse,
      `${this.getApiPath()}/b2b/auditoria/${this.sessionService.orderCode}`,
      undefined,
      false,
    )) as B2OrderAuditResponse;
  }
}
