import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import { RequestConfig } from '@/interfaces/request-config.interface';
import MetasClienteModel from '@/models/cr/metas-cliente.model';

@injectable()
export default class MetasService extends BaseService {
  async getClients(idContrat: string, codNomeCli: string): Promise<MetasClienteModel[]> {
    const config: RequestConfig = {
      params: {
        idContrato: idContrat,
        codNome: codNomeCli,
      },
    };
    return (await this.httpService.get(MetasClienteModel,
      `${this.getApiPath()}/cr/get-clients`, config)) as MetasClienteModel[];
  }

  async getConfigs(idContrat: string): Promise<MetasClienteModel[]> {
    const config: RequestConfig = {
      params: {
        idContrato: idContrat,
      },
    };
    return (await this.httpService.get(MetasClienteModel,
      `${this.getApiPath()}/cr/get-configs`, config)) as MetasClienteModel[];
  }

  async getConfigsChildren(idConfig: string, idContrat: string, codRepres: string):
  Promise<MetasClienteModel[]> {
    const config: RequestConfig = {
      params: {
        idConfigRepres: idConfig,
        idContrato: idContrat,
        codRepresentante: codRepres,
      },
    };
    return (await this.httpService.get(MetasClienteModel,
      `${this.getApiPath()}/cr/get-configs-children`, config)) as MetasClienteModel[];
  }

  async getConfigsGrandChildren(idConfig: string, idContrat: string, codRepres: string, codCli: string):
  Promise<MetasClienteModel[]> {
    const config: RequestConfig = {
      params: {
        idConfigRepres: idConfig,
        idContrato: idContrat,
        codRepresentante: codRepres,
        codCliente: codCli,
      },
    };
    return (await this.httpService.get(MetasClienteModel,
      `${this.getApiPath()}/cr/get-configs-grandchildren`, config)) as MetasClienteModel[];
  }

  async saveConfigs(configs: MetasClienteModel[],
    idContrat: string,
    gerarMetasRepres: boolean,
    gerarMetasCli: boolean): Promise<MetasClienteModel[]> {
    const config: RequestConfig = {
      params: {
        idContrato: idContrat,
        gerarMetasRepres: gerarMetasRepres ? '1' : '0',
        gerarMetasCli: gerarMetasCli ? '1' : '0',
      },
    };

    return (await this.httpService.post(
      MetasClienteModel,
      `${this.getApiPath()}/cr/save-configs?token=${this.getApiToken()}`,
      JSON.parse(JSON.stringify(configs, (key, value) => (typeof value === 'bigint' ? value.toString()
        : value))),
      config,
    )) as MetasClienteModel[];
  }

  async saveConfigsLinha(configs: MetasClienteModel[],
    idContrat: string): Promise<MetasClienteModel[]> {
    const config: RequestConfig = {
      params: {
        idContrato: idContrat,
      },
    };

    return (await this.httpService.post(
      MetasClienteModel,
      `${this.getApiPath()}/cr/save-configs-linha?token=${this.getApiToken()}`,
      JSON.parse(JSON.stringify(configs, (key, value) => (typeof value === 'bigint' ? value.toString()
        : value))),
      config,
    )) as MetasClienteModel[];
  }
}
