


















import { Vue, Component } from 'vue-property-decorator';
import store from '@/store/store';
import NotificationModel from '@/models/notification.model';

@Component({
  store,
})
export default class Notification extends Vue {
  get notifications(): NotificationModel[] {
    return this.$notify.notifications();
  }
}
