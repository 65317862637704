import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import UserSettingsModel from '@/models/user-settings.model';

@injectable()
export default class AttendantService extends BaseService {
  async getCrmSettings(): Promise<UserSettingsModel> {
    return (await this.httpService.get(
      UserSettingsModel,
      `${this.getApiPath()}attendant/settings`,
    )) as UserSettingsModel;
  }
}
