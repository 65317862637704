import { TransformationType, TransformFnParams } from 'class-transformer';
import dayjs from '@/plugins/dayjs';
import { toIsoDateTimeString } from '../formatters/to-iso-date-time-string';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TimeTransform = (params: TransformFnParams): string | Date | null => {
  if (params.type === TransformationType.CLASS_TO_PLAIN) {
    return toIsoDateTimeString(dayjs(params.value, ['HH:mm:ss']), true);
  }

  return dayjs(params.value, ['HH:mm:ss', 'HH:mm:ss A', 'HH:mm:ss a']).format('HH:mm:ss');
};
