import { injectable } from 'inversify-props';
import { classToPlain } from 'class-transformer';
import { cloneDeep } from 'lodash';
import BaseService from '@/services/base-service';
import JustificationManagementModel from '@/models/crm/justification-management.model';
import ReasonManagementModel from '@/models/crm/reason-management.model';

@injectable()
export default class JustificationManagementService extends BaseService {
  async getReasons(): Promise<ReasonManagementModel[]> {
    return (await this.httpService.get(
      ReasonManagementModel,
      `${this.getApiPath()}/crm/gestaoclientes/motivo`,
    )) as ReasonManagementModel[];
  }

  async confirm(justification: JustificationManagementModel): Promise<JustificationManagementModel[]> {
    return (await this.httpService.post(
      JustificationManagementModel,
      `${this.getApiPath()}/crm/gestaoclientes/justificar`, classToPlain(cloneDeep(justification)),
    )) as JustificationManagementModel[];
  }
}
