import { inject, injectable } from 'inversify-props';
import axios, { AxiosInstance } from 'axios';
import MotivoParadaEoxModel from '@/models/external/motivo-parada-eox.model';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import SessionService from '../session.service';

@injectable()
export default class EoxService {
  private axios: AxiosInstance;

  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  constructor() {
    this.axios = axios.create();
  }

  async getMotivosParada(): Promise<MotivoParadaEoxModel[]> {
    const result = await this.axios.get<MotivoParadaEoxModel[]>(
      `${this.sessionService.partnerApiCallCenter}/vocallagent/paradas.php`,
    );

    return Promise.resolve(result.data);
  }
}
