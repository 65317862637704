import { Type } from 'class-transformer';
import ConversationTemplateVariableModel from '@/models/crm/conversation-template-variable.model';

export default class ConversationTemplateModel {
  id!: number;

  waTemplateName!: string;

  waTemplateNamespace!: string;

  nomeAmigavel!: string;

  textoModeloMensagem!: string;

  ativo!: number;

  urlImagem!: string;

  @Type(() => ConversationTemplateVariableModel)
  variables!: ConversationTemplateVariableModel[];

  get isActive(): boolean {
    return !!this.ativo;
  }
}
