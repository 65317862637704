import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import BaseService from '@/services/base-service';
import EmailModel from '@/models/crm/email.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import { RequestConfig } from '@/interfaces/request-config.interface';
import { PaginationModel } from '@/models/pagination.model';

@injectable()
export default class EmailService extends BaseService {
  async getEmails(clientId: string, type: ClientTypeEnum): Promise<EmailModel[]> {
    const config: RequestConfig = {
      params: {
        clientId,
        type,
      },
    };
    return (await this.httpService.get(EmailModel, `${this.getApiPath()}/crm/email/list`, config)) as EmailModel[];
  }

  async queryEmails(
    page: number,
    limit: number,
    filter: unknown,
    orderBy?: string,
  ): Promise<PaginationModel<EmailModel>> {
    const config: RequestConfig = {
      params: {
        page,
        limit,
      },
    };

    if (orderBy) {
      config.params.orderBy = orderBy;
    }

    return (await this.httpService.post(
      PaginationModel,
      `${this.getApiPath()}/crm/email/query`,
      filter,
      config,
    )) as PaginationModel<EmailModel>;
  }

  static generateEmailExportFilename(date: Date): string {
    return `Empresa_Emails_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }

  static generateEmailsReportExportFilename(date: Date): string {
    return `Emails_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
