import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import { IStatisticsFilter } from '@/interfaces/crm/statistics-filter.interface';
import StatisticsPerCity from '@/models/crm/statistics-per-city.model';
import StatisticsPerAttendant from '@/models/crm/statistics-per-attendant.model';
import StatisticsPerDay from '@/models/crm/statistics-per-day.model';
import StatisticsPerRepresentative from '@/models/crm/statistics-per-representative.model';
import StatisticsPerCollection from '@/models/crm/statistics-per-collection.model';

@injectable()
export default class StatisticsService extends BaseService {
  async getContactedClientsPerCity(filter: IStatisticsFilter): Promise<StatisticsPerCity[]> {
    return (await this.httpService.post(
      StatisticsPerCity,
      `${this.getApiPath()}/crm/statistics/contacted-clients-per-city`,
      filter,
    )) as StatisticsPerCity[];
  }

  async getContactedClientsPerAttendant(filter: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/contacted-clients-per-attendant`,
      filter,
    )) as StatisticsPerAttendant[];
  }

  async getContactedClientsPerDay(filter: IStatisticsFilter): Promise<StatisticsPerDay[]> {
    return (await this.httpService.post(
      StatisticsPerDay,
      `${this.getApiPath()}/crm/statistics/contacted-clients-per-day`,
      filter,
    )) as StatisticsPerDay[];
  }

  async getConvertedClientsPerCity(filter: IStatisticsFilter): Promise<StatisticsPerCity[]> {
    return (await this.httpService.post(
      StatisticsPerCity,
      `${this.getApiPath()}/crm/statistics/converted-clients-per-city`,
      filter,
    )) as StatisticsPerCity[];
  }

  async getConvertedClientsPerAttendant(filter: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/converted-clients-per-attendant`,
      filter,
    )) as StatisticsPerAttendant[];
  }

  async getConvertedClientsPerDay(filter: IStatisticsFilter): Promise<StatisticsPerDay[]> {
    return (await this.httpService.post(
      StatisticsPerDay,
      `${this.getApiPath()}/crm/statistics/converted-clients-per-day`,
      filter,
    )) as StatisticsPerDay[];
  }

  async getOpeningOfNewClientsPerCity(filter: IStatisticsFilter): Promise<StatisticsPerCity[]> {
    return (await this.httpService.post(
      StatisticsPerCity,
      `${this.getApiPath()}/crm/statistics/opening-of-new-clients-per-city`,
      filter,
    )) as StatisticsPerCity[];
  }

  async getOpeningOfNewClientsPerAttendant(filter: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/opening-of-new-clients-per-attendant`,
      filter,
    )) as StatisticsPerAttendant[];
  }

  async getAverageTicketPerCity(filter: IStatisticsFilter): Promise<StatisticsPerCity[]> {
    return (await this.httpService.post(
      StatisticsPerCity,
      `${this.getApiPath()}/crm/statistics/average-ticket-per-city`,
      filter,
    )) as StatisticsPerCity[];
  }

  async getAverageTicketPerRepresentative(filter: IStatisticsFilter): Promise<StatisticsPerRepresentative[]> {
    return (await this.httpService.post(
      StatisticsPerRepresentative,
      `${this.getApiPath()}/crm/statistics/average-ticket-per-representative`,
      filter,
    )) as StatisticsPerRepresentative[];
  }

  async getAverageDiscountPerCity(filter: IStatisticsFilter): Promise<StatisticsPerCity[]> {
    return (await this.httpService.post(
      StatisticsPerCity,
      `${this.getApiPath()}/crm/statistics/average-discount-per-city`,
      filter,
    )) as StatisticsPerCity[];
  }

  async getAverageDiscountPerRepresentative(filter: IStatisticsFilter): Promise<StatisticsPerRepresentative[]> {
    return (await this.httpService.post(
      StatisticsPerRepresentative,
      `${this.getApiPath()}/crm/statistics/average-discount-per-representative`,
      filter,
    )) as StatisticsPerRepresentative[];
  }

  async getAveragePaymentTermPerCity(filter: IStatisticsFilter): Promise<StatisticsPerCity[]> {
    return (await this.httpService.post(
      StatisticsPerCity,
      `${this.getApiPath()}/crm/statistics/average-payment-term-per-city`,
      filter,
    )) as StatisticsPerCity[];
  }

  async getAveragePaymentTermPerRepresentative(filter: IStatisticsFilter): Promise<StatisticsPerRepresentative[]> {
    return (await this.httpService.post(
      StatisticsPerRepresentative,
      `${this.getApiPath()}/crm/statistics/average-payment-term-per-representative`,
      filter,
    )) as StatisticsPerRepresentative[];
  }

  async getAverageAttendanceTimePerAttendant(filter: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/average-attendance-time-per-attendant`,
      filter,
    )) as StatisticsPerAttendant[];
  }

  async getTotalOpenConversationsPerAttendant(filter: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/chats-per-attendant`,
      filter,
    )) as StatisticsPerAttendant[];
  }

  async getTotalAttendancesPerAttendant(filtro: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/attendances-per-attendant`,
      filtro,
    )) as StatisticsPerAttendant[];
  }

  async getClosedAttendancesPerAttendant(filtro: IStatisticsFilter): Promise<StatisticsPerAttendant[]> {
    return (await this.httpService.post(
      StatisticsPerAttendant,
      `${this.getApiPath()}/crm/statistics/attendances-per-attendant`,
      filtro,
    )) as StatisticsPerAttendant[];
  }

  async getOrdersPerRepresentative(filter: IStatisticsFilter): Promise<StatisticsPerRepresentative[]> {
    return (await this.httpService.post(
      StatisticsPerRepresentative,
      `${this.getApiPath()}/crm/statistics/orders-per-representative`,
      filter,
    )) as StatisticsPerRepresentative[];
  }

  async getOrdersPerCollection(filter: IStatisticsFilter): Promise<StatisticsPerCollection[]> {
    return (await this.httpService.post(
      StatisticsPerCollection,
      `${this.getApiPath()}/crm/statistics/orders-per-collection`,
      filter,
    )) as StatisticsPerCollection[];
  }

  public static getChartBackgroundColor(accent = false): string[] {
    if (accent) {
      return [
        '#4a7ba7',
        '#ff556e',
        '#003f5c',
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        '#238b00',
        '#658600',
        '#937c00',
        '#bc6c00',
        '#df5403',
        '#f93340',
        '#ff0572',
        '#ff18a9',
      ];
    }

    return [
      '#4a7ba7',
      '#ff556e',
      '#a17fbd',
      '#cc7eb6',
      '#f9679f',
      '#ff8b8a',
      '#d58cd3',
      '#ff9e6f',
      '#4dce8a',
      '#e4b349',
      '#cfba48',
      '#bac04d',
      '#a3c557',
      '#8ac966',
      '#f6ab50',
      '#6fcc77',
      '#c697dd',
      '#feb85a',
      '#e380c6',
      '#ef81a3',
      '#ef74b4',
      '#ff5d88',
      '#737fb8',
      '#fe5252',
      '#74dd72',
      '#8adf69',
      '#9fe061',
      '#b2e159',
      '#c6e253',
      '#d9e24f',
      '#ebe24d',
      '#fee14d',
      '#9247a7',
      '#8353ae',
      '#735db3',
      '#6565b4',
      '#586cb4',
      '#4f72b1',
      '#4a77ad',
      '#4a7ba7',
      '#4a82b3',
      '#4a8abf',
      '#4a92cb',
      '#4999d8',
      '#47a1e4',
      '#44a9f1',
      '#41b1fe',
      '#00c1ff',
      '#00d0ff',
      '#00ddfe',
      '#00e8e6',
      '#00f2c3',
      '#00f998',
      '#41fe67',
      '#61fe60',
      '#79ff5a',
      '#8dff54',
      '#9eff4f',
      '#afff4b',
      '#befe48',
      '#ccfe46',
      '#a03c96',
      '#aa3284',
      '#af2972',
      '#b22360',
      '#b1224e',
      '#ad263d',
      '#a72c2c',
    ];
  }
}
