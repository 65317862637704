import 'reflect-metadata';
import Vue from 'vue';
import axios from 'axios';
import VComp from '@vue/composition-api';
import { i18n, loadLanguageAsync, getCurrentLocale } from '@/i18n-setup';
import App from '@/app.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store/store';
import '@/plugins/loader';
import '@/plugins/mask';
import '@/plugins/dayjs';
import '@/plugins/tiptap-vuetify';
import '@/plugins/ag-grid';
import '@/plugins/uppy';
import '@/plugins/media-recorder';
import vuetify from '@/plugins/vuetify';
import notifier from '@/plugins/notifier';
import callCenter from '@/plugins/call-center';
import buildDependencyContainer from '@/app.container';
import EoxCallCenter from './services/call-center/eox-call-center.service';

buildDependencyContainer();
loadLanguageAsync(getCurrentLocale(), ['global', 'agGrid']);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VComp);
Vue.use(notifier, store);

// Quando tiver mais parceiros, será necessario identificar qual plugin instalar.
Vue.use(callCenter, new EoxCallCenter());

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
