import { TransformationType, TransformFnParams } from 'class-transformer';
import { toIsoDateTimeString } from '@/utils/formatters/to-iso-date-time-string';
import { toDate } from '@/utils/formatters/to-date';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DateTransform = (params: TransformFnParams): string | Date | null => {
  if (params.type === TransformationType.CLASS_TO_PLAIN) {
    return toIsoDateTimeString(params.value, true);
  }

  return toDate(params.value);
};
