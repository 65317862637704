import { injectable } from 'inversify-props';
import BaseService from '@/services/base-service';
import StateModel from '@/models/state.model';
import CityModel from '@/models/city.model';

@injectable()
export default class LocalizationService extends BaseService {
  async getStates(): Promise<StateModel[]> {
    return (await this.httpService.get(StateModel, `${this.getApiPath()}/estado/list`)) as StateModel[];
  }

  async getCities(uf: string): Promise<CityModel[]> {
    return (await this.httpService.get(CityModel, `${this.getApiPath()}/municipio/${uf}/list`)) as CityModel[];
  }
}
