import { i18n } from '@/i18n-setup';
import dayjs from '@/plugins/dayjs';

export abstract class DateHelper {
  static formatToIsoDateString(date: Date | dayjs.Dayjs): string {
    if (!date) {
      return date;
    }

    return dayjs(date).format('YYYY-MM-DD');
  }

  static formatToIsoDateTimeString(date: Date | dayjs.Dayjs, timezone = false): string {
    if (!date) {
      return date;
    }

    return timezone ? dayjs(date).toISOString() : dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  static formatToLocale(date: Date | string | dayjs.Dayjs, format = 'short'): string {
    if (!date) {
      return date;
    }

    let parsedValue: Date;

    if (date instanceof Date) {
      parsedValue = date;
    } else {
      parsedValue = dayjs(date).toDate();
    }

    return date ? `${i18n.d(parsedValue, format)}` : '';
  }

  static getLocaleDateFormat(locale: string): string {
    return dayjs()
      .locale(locale)
      .localeData()
      .longDateFormat('L');
  }

  static getTodayPeriod(fromDate?: Date): { start: Date; end: Date } {
    const from = dayjs(fromDate);

    return {
      start: from.startOf('day').toDate(),
      end: from.endOf('day').toDate(),
    };
  }

  static getYesterdayPeriod(fromDate?: Date): { start: Date; end: Date } {
    const from = dayjs(fromDate);

    return {
      start: from
        .subtract(1, 'day')
        .startOf('day')
        .toDate(),
      end: from
        .subtract(1, 'day')
        .endOf('day')
        .toDate(),
    };
  }

  static getCurrentMonthPeriod(fromDate?: Date): { start: Date; end: Date } {
    const from = dayjs(fromDate);

    return {
      start: from.startOf('month').toDate(),
      end: from.endOf('month').toDate(),
    };
  }

  static getCurrentYearPeriod(fromDate?: Date): { start: Date; end: Date } {
    const from = dayjs(fromDate);

    return {
      start: from.startOf('year').toDate(),
      end: from.endOf('year').toDate(),
    };
  }

  static getLastMonthsPeriod(monthsBefore: number, fromDate?: Date): { start: Date; end: Date } {
    const from = dayjs(fromDate);

    return {
      start: from
        .subtract(monthsBefore, 'month')
        .startOf('month')
        .toDate(),
      end: from
        .subtract(1, 'month')
        .endOf('month')
        .toDate(),
    };
  }

  static getLastYearsPeriod(yearsBefore: number, fromDate?: Date): { start: Date; end: Date } {
    const from = dayjs(fromDate);

    return {
      start: from
        .subtract(yearsBefore, 'year')
        .startOf('year')
        .toDate(),
      end: from
        .subtract(1, 'year')
        .endOf('year')
        .toDate(),
    };
  }

  static setTime(date: Date | dayjs.Dayjs, hour: number, minute: number, second = 0): Date {
    let dateObj = dayjs(date);
    dateObj = dateObj.set('hours', hour);
    dateObj = dateObj.set('minutes', minute);
    dateObj = dateObj.set('seconds', second);
    return dateObj.toDate();
  }
}
