import B2AuditAlocationModel from './audit-alocation.model';
import B2AuditMessagesModel from './audit-messages.model';
import B2AuditStoreModel from './audit-stores.model';

export default class B2OrderAuditModel {
  codPedido?: string;

  dataEmissao?: string;

  geradoEm?: string;

  situacaoAlocacao?: string;

  situacaoEnvio?: string;

  alocacao?: B2AuditAlocationModel[];

  lojas?: B2AuditStoreModel[];

  auditoria?: B2AuditMessagesModel[];

  constructor() {
    this.codPedido = undefined;
    this.dataEmissao = undefined;
    this.geradoEm = undefined;
    this.situacaoAlocacao = undefined;
    this.situacaoEnvio = undefined;
    this.alocacao = [];
    this.lojas = [];
    this.auditoria = [];
  }
}
