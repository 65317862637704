import { injectable } from 'inversify-props';
import dayjs from 'dayjs';
import BaseService from '@/services/base-service';
import VisitMadeModel from '@/models/crm/visit-made.model';

@injectable()
export default class VisitMadeService extends BaseService {
  async getVisits(clientId: string): Promise<VisitMadeModel[]> {
    return (await this.httpService.get(
      VisitMadeModel,
      `${this.getApiPath()}/crm/visita-realizada/list?cnpj=${clientId}`,
    )) as VisitMadeModel[];
  }

  static generateVisitExportFilename(date: Date): string {
    return `Empresa_Visitas_${dayjs(date).format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
  }
}
