import VariableColumnModel from '@/models/configurator/variables/variable-column.model';

export default class LayoutEmailModel {
  name = '';

  html = '';

  code = -1;

  columns: VariableColumnModel[] = [];

  isDefault = false;

  orientation = 'Portrait';

  constructor() {
    this.name = '';
    this.html = '';
    this.code = -1;
    this.columns = [];
    this.isDefault = false;
    this.orientation = 'Portrait';
  }
}
