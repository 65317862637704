import { Type } from 'class-transformer';
import { AttachmentTypeEnum } from '@/enums/crm/attachment-type.enum';

export default class AttachmentModel {
  id!: number;

  clientId!: string;

  path!: string;

  src!: string;

  name!: string;

  description!: string;

  type!: AttachmentTypeEnum;

  @Type(() => Date)
  creationDate!: Date;
}
